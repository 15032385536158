<template>
  <ValidationObserver ref="form">
    <b-overlay
        id="overlay-background"
        :show="!hasLoaded"
        fixed
        no-wrap
        variant="light"
        opacity="0.85"
        blur="5px"
      >
      </b-overlay>
    <form @submit.prevent="validate">
      <h5 class="text-center">Indirizzo del cliente <i>(Opzionale)</i></h5>
      <div class="row">
        <div class="col-md-6">
          <ValidationProvider
            name="address"
            rules=""
            v-slot="{ passed, failed }"
          >
            <fg-input name="address"
                      :error="failed ? 'Il campo Indirizzo è obbligatorio': null"
                      :hasSuccess="passed"
                      placeholder="Indirizzo"
                      v-model="form.address"
                      addon-left-icon="nc-icon nc-square-pin">
            </fg-input>
          </ValidationProvider>
        </div>
        <div class="col-md-6">
          <ValidationProvider
            name="city"
            rules=""
            v-slot="{ passed, failed }"
          >
            <fg-input name="city"
                      :error="failed ? 'Il campo Città è obbligatorio': null"
                      :hasSuccess="passed"
                      placeholder="Città"
                      v-model="form.city"
                      addon-left-icon="nc-icon nc-istanbul">
            </fg-input>
          </ValidationProvider>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <ValidationProvider
            name="country"
            rules=""
            v-slot="{ passed, failed }"
          >
            <fg-input name="country"
                      :error="failed ? 'Il campo Nazione è obbligatorio': null"
                      :hasSuccess="passed"
                      placeholder="Nazione (es: Italia)"
                      v-model="form.country"
                      addon-left-icon="nc-icon nc-map-big">
            </fg-input>
          </ValidationProvider>
        </div>
        <div class="col-md-6">
          <ValidationProvider
            name="cap"
            rules=""
            v-slot="{ passed, failed }"
          >
            <fg-input name="cap"
                      :error="failed ? 'Il campo CAP è obbligatorio': null"
                      :hasSuccess="passed"
                      placeholder="CAP"
                      v-model="form.cap"
                      addon-left-icon="nc-icon nc-tag-content">
            </fg-input>
          </ValidationProvider>
        </div>
      </div>
      <div class="row custom_error" v-if="error">
        <p style="color:red;">{{ error }} </p>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
  import { Select, Option } from 'element-ui'
  import { extend } from "vee-validate";
  import { required, email, regex } from "vee-validate/dist/rules";
  import CustomerService from 'src/services/CustomerService'

  extend("required", required);
  extend("email", email);
  extend("regex", regex);

  export default {
    props: ['company'],
    components: {
      [Select.name]: Select,
      [Option.name]: Option
    },
    data () {
      return {
        hasLoaded: false,
        error: false,
        form: {
          address: '',
          city: '',
          country: '',
          cap: ''
        }
      }
    },
    async mounted() {
      try {
      } catch(err) {
        console.log(err)
        this.error = 'Errore durante il caricamento dei gruppi. Ricarica la pagina!'
      } finally {
        if (this.company && this.company.metas['indirizzo']) {
          this.form.address = this.company.metas['indirizzo'].indirizzo
          this.form.city = this.company.metas['indirizzo'].citta
          this.form.country = this.company.metas['indirizzo'].paese
          this.form.cap = this.company.metas['indirizzo'].cap
        }
        this.hasLoaded = true
      }
    },
    methods: {
      validate() {
        return this.$refs.form.validate().then(res => {
          this.$emit('on-validated', res)
          return res
        })
      }
    }
  }
</script>
<style>
</style>
