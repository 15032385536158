import Api from '@/services/Api'

export default {
  getCompanies () {
    return Api().get('/model/company?include=true')
  },
  getCompany (id) {
    return Api().get('/model/company/'+id+'?include=true')
  },
  createComapnies(data) {
    return Api().post('/model/company?include=true', data)
  },
  deleteCompany(id) {
    return Api().delete('/model/company/'+id)
  },
  updateCompany(id, data) {
    return Api().patch('/model/company/'+id, data)
  }
}