<template>
  <ValidationObserver ref="form">
    <b-overlay
        id="overlay-background"
        :show="!hasLoaded"
        fixed
        no-wrap
        variant="light"
        opacity="0.85"
        blur="5px"
      >
      </b-overlay>
    <form @submit.prevent="validate">
      <h5 class="text-center">Associa un gruppo al cliente, o creane uno.</h5>
      
      <div class="row">
        
        <div class="col-md-12">
          <ValidationProvider
            name="group"
            rules=""
            v-slot="{ failed }"
          >
          <fg-input label="Gruppo (Opzionale)"
                    :error="failed ? 'Il campo Gruppo è obbligatorio': null">            
            <el-select name="group" 
                      multiple class="select-primary"
                      size="large"
                      v-model="form.group"
                      placeholder="Seleziona un gruppo">
              <el-option v-for="group in groupOptions"
                        class="select-primary"
                        :value="group.value"
                        :label="group.label"
                        :key="group.value">
              </el-option>
            </el-select>
          </fg-input>
          </ValidationProvider>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-md-12">
          <i style="color:grey; font-size:9px;">*Se vuoi creare un nuovo gruppo, scrivi il nome nel campo "Nuovo Gruppo" e clicca sul bottone "Crea". Lo troverai nella lista dei tuoi gruppi</i>
        </div>
       </div> 
        <div class="row">
          
        <div class="col-md-6">
          <label>Crea un nuovo Gruppo</label>
          <autocomplete :search="search" :get-result-value="getResultValue" @submit="selected" data-position="below" class="autocomplete"></autocomplete>  
          
        </div>
        <div class="col-md-6">
          <l-button type="primary" style="margin-top:25px;display:inline;" :disabled="disableAttivaCrea" wide @click="createGroup()" >Crea</l-button>&nbsp;
        </div>
      </div>
      <div class="row custom_error" v-if="error">
        <p style="color:red;">{{ error }} </p>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
  import { Select, Option } from 'element-ui'
  import { extend } from "vee-validate";
  import { required, email, regex } from "vee-validate/dist/rules";
  import CustomerService from 'src/services/CustomerService'
  import Autocomplete from '@trevoreyre/autocomplete-vue'
  import '@trevoreyre/autocomplete-vue/dist/style.css'

  extend("required", required);
  extend("email", email);
  extend("regex", regex);

  export default {
    props: ['company'],
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      Autocomplete
    },
    data () {
      return {
        form: {
          group: null
        },
        groupOptions: [
        ],
        newGroup: '',
        hasLoaded: false,
        error: false,
        disableAttivaCrea:true
      }
    },
    watch:{
      newGroup: function(value){
        
          var value = this.groupOptions.map(el => el.label).filter(el => el.toUpperCase().indexOf(value.toUpperCase()) == 0)
          console.log("value",value)

      }
    },
    async mounted() {
      try {
        const customer = await CustomerService.getCustomer(this.$store.state.customer.user.id)
        if (customer) {
          this.groupOptions = customer.data.groups.map(el => {
            var obj = {}
            obj.value = el.id
            obj.label = el.label
            return obj
          })
          // this.groupOptions = [{value: null, label: '- gruppi -'}].concat(this.groupOptions)
        }
      } catch(err) {
        console.log(err)
        this.error = 'Errore durante il caricamento dei gruppi. Ricarica la pagina!'
      } finally {
        if (this.company) {
          this.form.group = this.company.groups.map(el => el.id)
        }
        this.hasLoaded = true
      }
    },
    methods: {
      selected(result){
        //todo oggetto selezionato
        console.log("brav1")
        console.log(result)
      },
      getResultValue(result){
        console.log("getreslt",result)
        if(result[0] =='vuoto' ){
          console.log("bravoxxxx")
        }
        return result.label          
      },
      search(input) {
        console.log("bravo2")
        if (input.length < 1) { 
          return []
        }
        const result = this.groupOptions.filter(group => {
          return group.label.toLowerCase()
            .indexOf(input.toLowerCase()) == 0
        })
        console.log("result",result)
        this.disableAttivaCrea = result.length>0 ? true : false
        this.newGroup = result.length>0 ? '' : input
        return result
      },
      validate() {
        return this.$refs.form.validate().then(res => {
          this.$emit('on-validated', res)
          return res
        })
      },
      async createGroup() {
        try {
          this.hasLoaded = false
          /**
           * controllare prima se un gruppo con questo nome già esiste
           */
          
          const group = await CustomerService.createGroup({
            customer_id: this.$store.state.customer.user.id,
            label: this.newGroup
          })
          if (group) {
            console.log('group', group)
            this.groupOptions = this.groupOptions.concat([{
              value: group.data.id,
              label: group.data.label
            }])
          }
        } catch(err) {
          console.log('err', err)
          this.error = 'Errore durante la creazione di un gruppo. Ricarica la pagina!'
        } finally {
          this.hasLoaded = true
          this.newGroup = ''
        }
      }
    }
  }
</script>
<style>
.autocomplete-input{
  background-color:white;
  padding: 3px 12px 5px 5px;
  background-image: none;
}
</style>
