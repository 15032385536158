<template>
  <ValidationObserver ref="form">
    <b-overlay
        id="overlay-background"
        :show="!hasLoaded"
        fixed
        no-wrap
        variant="light"
        opacity="0.85"
        blur="5px"
      >
      </b-overlay>
    <form @submit.prevent="validate">
      <h5 class="text-center">Contatti del cliente</h5>
      <div class="row">
        <div class="col-md-6">
          <ValidationProvider
            name="email"
            rules="required|email"
            v-slot="{ passed, failed }"
          >
            <fg-input name="email"
                      :error="failed ? 'Il campo email è obbligatorio': null"
                      :hasSuccess="passed"
                      placeholder="Email (obbligatorio)"
                      v-model="form.email"
                      addon-left-icon="nc-icon nc-email-83">
            </fg-input>
          </ValidationProvider>
        </div>
        <div class="col-md-6">
          <ValidationProvider
            name="tel"
            rules=""
            v-slot="{ passed, failed }"
          >
            <fg-input name="tel"
                      :error="failed ? 'Il campo Telefono è obbligatorio': null"
                      :hasSuccess="passed"
                      placeholder="Telefono"
                      v-model="form.tel"
                      addon-left-icon="nc-icon nc-tablet-2">
            </fg-input>
          </ValidationProvider>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <ValidationProvider
            name="cell"
            rules=""
            v-slot="{ passed, failed }"
          >
            <fg-input name="cell"
                      :error="failed ? 'Il campo Cellulare è obbligatorio': null"
                      :hasSuccess="passed"
                      placeholder="Cellulare"
                      v-model="form.cell"
                      addon-left-icon="nc-icon nc-mobile">
            </fg-input>
          </ValidationProvider>
        </div>
        <div class="col-md-6">
          <ValidationProvider
            name="pec"
            rules=""
            v-slot="{ passed, failed }"
          >
            <fg-input name="pec"
                      :error="failed ? 'Il campo PEC è obbligatorio': null"
                      :hasSuccess="passed"
                      placeholder="PEC"
                      v-model="form.pec"
                      addon-left-icon="nc-icon nc-single-copy-04">
            </fg-input>
          </ValidationProvider>
        </div>
      </div>
      <div class="row custom_error" v-if="error">
        <p style="color:red;">{{ error }} </p>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
  import { Select, Option } from 'element-ui'
  import { extend } from "vee-validate";
  import { required, email, regex } from "vee-validate/dist/rules";
  import CustomerService from 'src/services/CustomerService'

  extend("required", required);
  extend("email", email);
  extend("regex", regex);

  export default {
    props: ['company'],
    components: {
      [Select.name]: Select,
      [Option.name]: Option
    },
    data () {
      return {
        hasLoaded: false,
        error: false,
        form: {
          email: '',
          tel: '',
          cell: '',
          pec: ''
        }
      }
    },
    async mounted() {
      try {
      } catch(err) {
        console.log(err)
        this.error = 'Errore durante il caricamento dei gruppi. Ricarica la pagina!'
      } finally {
        if (this.company && this.company.metas['contatti']) {
          this.form.email = this.company.metas['contatti'].email
          this.form.tel = this.company.metas['contatti'].telefono
          this.form.cell = this.company.metas['contatti'].cell
          this.form.pec = this.company.metas['contatti'].pec
        }
        this.hasLoaded = true
      }
    },
    methods: {
      validate() {
        return this.$refs.form.validate().then(res => {
          this.$emit('on-validated', res)
          return res
        })
      }
    }
  }
</script>
<style>
</style>
