<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <h5 class="text-center">Informazioni personali dell'azienda o persona</h5>
      <div class="row">
        <div class="col-md-6">
          <ValidationProvider
            name="firstName"
            rules="required"
            v-slot="{ passed, failed }"
          >
            <fg-input name="firstName"
                      :error="failed ? 'Il campo Nome è obbligatorio': null"
                      :hasSuccess="passed"
                      placeholder="Nome (obbligatorio)"
                      v-model="form.firstName"
                      addon-left-icon="nc-icon nc-single-02">
            </fg-input>
          </ValidationProvider>
        </div>
        <div class="col-md-6">
          <ValidationProvider
            name="lastName"
            rules="required"
            v-slot="{ passed, failed }"
          >
            <fg-input name="lastName"
                      :error="failed ? 'Il campo Cognome è obbligatorio': null"
                      :hasSuccess="passed"
                      placeholder="Cognome (obbligatorio)"
                      v-model="form.lastName"
                      addon-left-icon="nc-icon nc-circle-10">
            </fg-input>
          </ValidationProvider>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <ValidationProvider
            name="codiceFiscale"
            rules=""
          >
            <fg-input name="codiceFiscale"
                      placeholder="Codice Fiscale (non obbligatorio)"
                      v-model="form.codiceFiscale"
                      addon-left-icon="nc-icon nc-circle-10">
            </fg-input>
          </ValidationProvider>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!-- <ValidationProvider
            name="email"
            rules="required|email"
            v-slot="{ passed, failed }"
          >
            <fg-input name="email"
                      :error="failed ? 'The Email field is required': null"
                      :hasSuccess="passed"
                      placeholder="Email (required)"
                      v-model="email">
            </fg-input>
          </ValidationProvider> -->
          <fg-input>
            <checkbox v-model="form.isCompany"
                      name="isCompany">Azienda
            </checkbox>
          </fg-input>
        </div>
      </div>
      <div class="row" v-if="form.isCompany">
        <div class="col-md-6">
          <ValidationProvider
            name="ragioneSociale"
            rules="required"
            v-slot="{ passed, failed }"
          >
            <fg-input name="ragioneSociale"
                      :error="failed ? 'Il campo Ragione Sociale è obbligatorio': null"
                      :hasSuccess="passed"
                      placeholder="Ragione Sociale (obbligatorio)"
                      v-model="form.ragioneSociale"
                      addon-left-icon="nc-icon nc-bank">
            </fg-input>
          </ValidationProvider>
        </div>
        <div class="col-md-6">
          <ValidationProvider
            name="partitaIva"
            rules=""
            v-slot="{ passed, failed }"
          >
            <fg-input name="partitaIva"
                      :error="failed ? 'Il campo Partita Iva è obbligatorio': null"
                      :hasSuccess="passed"
                      placeholder="Partita Iva (opzionale)"
                      v-model="form.partitaIva"
                      addon-left-icon="nc-icon nc-circle-10">
            </fg-input>
          </ValidationProvider>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
  import { extend } from "vee-validate";
  import { required, email } from "vee-validate/dist/rules";
  import {Checkbox} from 'src/components/index'

  extend("required", required);
  extend("email", email);

  export default {
    props: ['company'],
    data() {
      return {
        form: {
          firstName: "",
          lastName: "",
          isCompany: false,
          ragioneSociale: "",
          partitaIva: "",
          codiceFiscale:""
        }
      };
    },
    components: {
      Checkbox
    },
    mounted() {
      if (this.company) {
        console.log("company",JSON.stringify(this.company))
        this.form.firstName = this.company.first_name
        this.form.lastName = this.company.last_name
        this.form.isCompany = this.company.is_company
        this.form.ragioneSociale = this.company.ragione_sociale
        this.form.partitaIva = this.company.partita_iva
        this.form.codiceFiscale = this.company.codice_fiscale
      }
    },
    methods: {
       isValidFiscalCode(cf) {
        var regex  = /^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/;
        return regex.test(cf);
      },
      validate() {

        if(this.form.codiceFiscale && this.form.codiceFiscale.trim!=="" && !this. isValidFiscalCode(this.form.codiceFiscale)){          
          return false
        }
        
        return this.$refs.form.validate().then(res => {
          this.$emit('on-validated', res)
          return res
        })
      }
    }
  }
</script>
<style>
</style>
