var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('h5',{staticClass:"text-center"},[_vm._v("Informazioni personali dell'azienda o persona")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"firstName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"name":"firstName","error":failed ? 'Il campo Nome è obbligatorio': null,"hasSuccess":passed,"placeholder":"Nome (obbligatorio)","addon-left-icon":"nc-icon nc-single-02"},model:{value:(_vm.form.firstName),callback:function ($$v) {_vm.$set(_vm.form, "firstName", $$v)},expression:"form.firstName"}})]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"lastName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"name":"lastName","error":failed ? 'Il campo Cognome è obbligatorio': null,"hasSuccess":passed,"placeholder":"Cognome (obbligatorio)","addon-left-icon":"nc-icon nc-circle-10"},model:{value:(_vm.form.lastName),callback:function ($$v) {_vm.$set(_vm.form, "lastName", $$v)},expression:"form.lastName"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"codiceFiscale","rules":""}},[_c('fg-input',{attrs:{"name":"codiceFiscale","placeholder":"Codice Fiscale (non obbligatorio)","addon-left-icon":"nc-icon nc-circle-10"},model:{value:(_vm.form.codiceFiscale),callback:function ($$v) {_vm.$set(_vm.form, "codiceFiscale", $$v)},expression:"form.codiceFiscale"}})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('fg-input',[_c('checkbox',{attrs:{"name":"isCompany"},model:{value:(_vm.form.isCompany),callback:function ($$v) {_vm.$set(_vm.form, "isCompany", $$v)},expression:"form.isCompany"}},[_vm._v("Azienda\n          ")])],1)],1)]),(_vm.form.isCompany)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"ragioneSociale","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"name":"ragioneSociale","error":failed ? 'Il campo Ragione Sociale è obbligatorio': null,"hasSuccess":passed,"placeholder":"Ragione Sociale (obbligatorio)","addon-left-icon":"nc-icon nc-bank"},model:{value:(_vm.form.ragioneSociale),callback:function ($$v) {_vm.$set(_vm.form, "ragioneSociale", $$v)},expression:"form.ragioneSociale"}})]}}],null,false,1313045225)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"partitaIva","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"name":"partitaIva","error":failed ? 'Il campo Partita Iva è obbligatorio': null,"hasSuccess":passed,"placeholder":"Partita Iva (opzionale)","addon-left-icon":"nc-icon nc-circle-10"},model:{value:(_vm.form.partitaIva),callback:function ($$v) {_vm.$set(_vm.form, "partitaIva", $$v)},expression:"form.partitaIva"}})]}}],null,false,3681586391)})],1)]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }