var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form"},[_c('b-overlay',{attrs:{"id":"overlay-background","show":!_vm.hasLoaded,"fixed":"","no-wrap":"","variant":"light","opacity":"0.85","blur":"5px"}}),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('h5',{staticClass:"text-center"},[_vm._v("Contatti del cliente")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"name":"email","error":failed ? 'Il campo email è obbligatorio': null,"hasSuccess":passed,"placeholder":"Email (obbligatorio)","addon-left-icon":"nc-icon nc-email-83"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"tel","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"name":"tel","error":failed ? 'Il campo Telefono è obbligatorio': null,"hasSuccess":passed,"placeholder":"Telefono","addon-left-icon":"nc-icon nc-tablet-2"},model:{value:(_vm.form.tel),callback:function ($$v) {_vm.$set(_vm.form, "tel", $$v)},expression:"form.tel"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"cell","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"name":"cell","error":failed ? 'Il campo Cellulare è obbligatorio': null,"hasSuccess":passed,"placeholder":"Cellulare","addon-left-icon":"nc-icon nc-mobile"},model:{value:(_vm.form.cell),callback:function ($$v) {_vm.$set(_vm.form, "cell", $$v)},expression:"form.cell"}})]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"pec","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"name":"pec","error":failed ? 'Il campo PEC è obbligatorio': null,"hasSuccess":passed,"placeholder":"PEC","addon-left-icon":"nc-icon nc-single-copy-04"},model:{value:(_vm.form.pec),callback:function ($$v) {_vm.$set(_vm.form, "pec", $$v)},expression:"form.pec"}})]}}])})],1)]),(_vm.error)?_c('div',{staticClass:"row custom_error"},[_c('p',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.error)+" ")])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }