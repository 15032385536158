<template>
  <div>
    <div class="row d-flex justify-content-center">
      <div class="col-sm-8">
        <div class="card card-wizard" id="wizardCard">
          <form-wizard shape="tab"
                       @on-complete="wizardComplete"
                       error-color="#FB404B"
                       color="#35495E"
                       :title="company ? 'Modifica Cliente' : 'Creazione Cliente'"
                       :subtitle="company ? 'Modifica il cliente '+company.first_name + ' ' + company.last_name : 'Registra un tuo nuovo cliente'">
            <tab-content title="Informazioni personali"
                         class="col-12"
                         :before-change="() => validateStep('firstStep')"
                         icon="nc-icon nc-badge">
              <first-step ref="firstStep" :company="company" @on-validated="onStepValidated"></first-step>
            </tab-content>

            <tab-content title="Gruppo"
                         class="col-12"
                         :before-change="() => validateStep('secondStep')"
                         icon="nc-icon nc-notes">
              <second-step ref="secondStep" :company="company" @on-validated="onStepValidated"></second-step>
            </tab-content>

            <tab-content title="Indirizzo"
                         class="col-12"
                         :before-change="() => validateStep('thirdStep')"
                         icon="nc-icon nc-pin-3">
              <third-step ref="thirdStep" :company="company" @on-validated="onStepValidated"></third-step>
            </tab-content>

            <tab-content title="Contatti"
                         class="col-12"
                         :before-change="() => validateStep('contactStep')"
                         icon="nc-icon nc-email-85">
              <contact-step ref="contactStep" :company="company" @on-validated="onStepValidated"></contact-step>
            </tab-content>

            <tab-content title="Ultimo step"
                         class="col-12"
                         icon="nc-icon nc-check-2">
              <b-overlay
                id="overlay-background"
                :show="!hasLoaded"
                fixed
                no-wrap
                variant="light"
                opacity="0.85"
                blur="5px"
              >
              </b-overlay>
              <div>
                <h2 class="text-center text-space">Fine!
                  <br>
                  <small>Clicca su "<b>Fine</b>" per registrare un nuovo cliente</small>
                </h2>
              </div>

            </tab-content>

            <button slot="prev" class="btn btn-default btn-fill btn-wd btn-back">Back</button>
            <button slot="next" class="btn btn-default btn-fill btn-wd btn-next">Next</button>
            <button slot="finish" class="btn btn-success btn-fill btn-wd">Fine</button>
          </form-wizard>
          <div class="row custom_error" v-if="error">
            <p style="color:red;">{{ error }} </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {FormWizard, TabContent} from 'vue-form-wizard'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import FirstStep from './Wizard/FirstStep.vue'
  import SecondStep from './Wizard/SecondStep.vue'
  import ThirdStep from './Wizard/ThirdStep.vue'
  import ContactStep from './Wizard/ContactStep.vue'
  import CompanyService from 'src/services/CompanyService'
  import Swal from 'sweetalert2'

  export default {
    props: ['company'],
    data () {
      return {
        hasLoaded: true,
        error: null,
        wizardModel: {}
      }
    },
    components: {
      FormWizard,
      TabContent,
      FirstStep,
      SecondStep,
      ThirdStep,
      ContactStep
    },
    mounted() {
      if (this.company) {
        console.log('EDIT', this.company)
      } else {
        console.log('CREATE')
      }
    },
    methods: {
      validateStep(ref) {        
        return this.$refs[ref].validate()
      },
      onStepValidated(validated, model) {
        this.wizardModel = {...this.wizardModel, ...model}
      },
      async wizardComplete() {
        try {
          this.hasLoaded = false
          this.error = null
          var firststep_data = this.$refs['firstStep'].form
          var secondstep_data = this.$refs['secondStep'].form
          var thirdstep_data = this.$refs['thirdStep'].form
          var fourthtep_data = this.$refs['contactStep'].form
          // console.log(firststep_data, secondstep_data, thirdstep_data, fourthtep_data)
          
          var objToSend = {
            is_company: firststep_data.isCompany,
            first_name: firststep_data.firstName,
            last_name: firststep_data.lastName,
            ragione_sociale: firststep_data.ragioneSociale,
            partita_iva: firststep_data.partitaIva,
            codice_fiscale:firststep_data.codiceFiscale,
            group_id: secondstep_data.group,
            metas: {
              indirizzo: {
                indirizzo: thirdstep_data.address,
                citta: thirdstep_data.city,
                cap: thirdstep_data.cap,
                paese: thirdstep_data.country,
              },
              contatti: {
                email: fourthtep_data.email,
                telefono: fourthtep_data.tel,
                cell: fourthtep_data.cell,
                pec: fourthtep_data.pec,
              }
            }
          }
          if (this.company) {
            const company = await CompanyService.updateCompany(this.company.id, objToSend)
              if (company) {
                console.log('company edited', company)
              }
          } else {
            const company = await CompanyService.createComapnies(objToSend)
            if (company) {
              console.log('company created', company)
            }
          }
          
        } catch(err) {
          console.log('err', err)
          this.error = err
        } finally {
          this.hasLoaded = true
          if (!this.error) {
            Swal.fire({
              title: "Cliente creato correttamente",
              // text: "You clicked the finish button!",
              type: "success",
              confirmButtonClass: "btn btn-success",
              buttonsStyling: false
            });
            setTimeout(() => {
              this.$router.push({
                path: '/admin/gestione-clienti'
              })
            })
          }
        }
      }
    }
  }
</script>
<style lang="scss">
  .vue-form-wizard .wizard-icon-circle.tab_shape {
    background-color: #9A9A9A !important;
    color: white;
  }
  .vue-form-wizard .wizard-tab-content {
    display: flex; // to avoid horizontal scroll when animating
    .wizard-tab-container {
      display: block;
      animation: fadeIn 0.5s;
    }
  }
</style>
