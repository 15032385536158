var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form"},[_c('b-overlay',{attrs:{"id":"overlay-background","show":!_vm.hasLoaded,"fixed":"","no-wrap":"","variant":"light","opacity":"0.85","blur":"5px"}}),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('h5',{staticClass:"text-center"},[_vm._v("Indirizzo del cliente "),_c('i',[_vm._v("(Opzionale)")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"address","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"name":"address","error":failed ? 'Il campo Indirizzo è obbligatorio': null,"hasSuccess":passed,"placeholder":"Indirizzo","addon-left-icon":"nc-icon nc-square-pin"},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}})]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"city","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"name":"city","error":failed ? 'Il campo Città è obbligatorio': null,"hasSuccess":passed,"placeholder":"Città","addon-left-icon":"nc-icon nc-istanbul"},model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", $$v)},expression:"form.city"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"country","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"name":"country","error":failed ? 'Il campo Nazione è obbligatorio': null,"hasSuccess":passed,"placeholder":"Nazione (es: Italia)","addon-left-icon":"nc-icon nc-map-big"},model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}})]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"cap","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"name":"cap","error":failed ? 'Il campo CAP è obbligatorio': null,"hasSuccess":passed,"placeholder":"CAP","addon-left-icon":"nc-icon nc-tag-content"},model:{value:(_vm.form.cap),callback:function ($$v) {_vm.$set(_vm.form, "cap", $$v)},expression:"form.cap"}})]}}])})],1)]),(_vm.error)?_c('div',{staticClass:"row custom_error"},[_c('p',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.error)+" ")])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }